interface IProps {
  lang: string
}
const Banner = ({ lang }: IProps) =>
  lang === 'pl' ? (
    <section className='mx-auto relative overflow-hidden'>
      <div className='md:container'>
        <div className='flex flex-wrap'>
          <a
            href='https://landings.bulldogjob.pl/diversity-in-polish-it-2024'
            target='_blank'
            className='sm:hover:scale-105 duration-200'
            rel='noreferrer nofollow'
          >
            <picture className='hidden sm:block'>
              <source srcSet='/next-images/diversity.webp' type='image/webp' />
              <img
                src='/next-images/diversity.jpg'
                alt='Diversity w polskim IT'
                className='w-full aspect-[1200/97]'
              />
            </picture>
            <picture className='block sm:hidden'>
              <source
                srcSet='/next-images/diversity-mobile.webp'
                type='image/webp'
              />
              <img
                src='/next-images/diversity-mobile.jpg'
                alt='Diversity w polskim IT'
                className='w-full aspect-[4/1]'
              />
            </picture>
          </a>
        </div>
      </div>
    </section>
  ) : null

export default Banner
