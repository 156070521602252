/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import useTranslation from 'next-translate/useTranslation'

import { Job } from 'generated/apolloComponents'
import { useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import JobListItemWrapper from '@/components/molecules/JobListItem/JobListItemWrapper'

import { arrayToObject, isEmpty } from '@/helpers/utils'
import Pagination from '@/components/molecules/Pagination'
import {
  ActionType,
  convertJobsFiltersSlugToURL,
  JobListContext,
} from '@/context/jobListContext'
import { useMe } from '@/context/meContext'
import JobFiltersHeader from '../organisms/JobFiltersHeader'
import HomeSlogan from '../organisms/HomeSlogan'
import JobFiltersFooterMobile from '../organisms/JobFiltersFooterMobile'
import JobListIcons from '../molecules/JobListIcons'
import Banner from '../molecules/Banner'

interface iProps {
  jobs: Job[]
  jobsLocations: string[]
  totalCount: number
  slugState?: any
}

const JobListHome = ({
  jobs,
  jobsLocations,
  totalCount,
  slugState,
}: iProps) => {
  const router = useRouter()
  const [jobsList, setJobsList] = useState<Job[]>(jobs)
  const [jobsCount, setJobsCount] = useState(totalCount)
  const isMounted = useRef(false)
  const { state, dispatch } = useContext(JobListContext)
  const dynamicRoute = useRouter().asPath
  const { me, loadMe } = useMe()
  const { t, lang } = useTranslation('common')

  const [minSalaryFilter, setMinSalaryFilter] = useState<number | null>(null)

  useEffect(() => {
    if (router.asPath === '/companies/jobs') {
      dispatch({
        type: ActionType.ChangeFilter,
        payload: { experienceLevel: [], city: [], role: [], skills: [] },
      })
    }
  }, [dynamicRoute])

  useEffect(() => {
    const slug = convertJobsFiltersSlugToURL(state)

    if (slug) {
      router.push({
        pathname: `/companies/jobs${slug}`,
      })
    }
  }, [state, me])

  useEffect(() => {
    loadMe(true)
  }, [state])

  useEffect(() => {
    if (slugState && !isEmpty(slugState)) {
      dispatch({ type: ActionType.SetState, payload: slugState })
    }
  }, [])

  const handlePageChange = (page: number) => {
    dispatch({ type: ActionType.ChangePage, payload: page })
  }

  return (
    <>
      {/* <ReactTooltip id='tooltip' /> */}
      <Banner lang={lang} />
      <HomeSlogan />
      <JobFiltersHeader
        jobsLocations={arrayToObject(jobsLocations)}
        slugState={slugState}
        setIsVisible={() => {}}
        redirect
      />
      <div className='bg-gray-bg pt-8 relative'>
        <div className='container'>
          <JobListIcons />
          {jobsList.length > 0 &&
            jobsList.map((job) => (
              <JobListItemWrapper
                key={job.id}
                job={job}
                me={me!}
                minSalaryFilter={minSalaryFilter}
              />
            ))}
          <div className='flex justify-center py-12'>
            <a href='/companies/jobs/s/page,2' rel='nofollow'>
              <button type='button' className='button-primary'>
                {t('common:show_more_jobs')}
              </button>
            </a>
          </div>
        </div>
        <JobFiltersFooterMobile
          handleOrderChange={() => {}}
          slugState={slugState}
          setIsVisible={() => {}}
          totalCount={totalCount}
          redirect
        />
        <Pagination
          className='pagination-bar'
          currentPage={state.page}
          totalCount={jobsCount}
          pageSize={state.perPage}
          onPageChange={(page: number) => handlePageChange(page)}
        />
      </div>
    </>
  )
}

export default JobListHome
